import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Theme,
  IconButton,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme: Theme) => ({
  iconbtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  icon: {
    fontSize: '0.8em',
  },
}));

interface Props {
    value: number,
    setValue: any,
    maxValue?: number,
    disabled?: boolean,
}

export default function AddRemoveButton({
  value,
  setValue,
  maxValue = 100,
  disabled = false,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>

      <Grid item>
        <IconButton
          onClick={
            () :void => { setValue(Math.max(value - 1, 0)); }
          }
          size="small"
          className={classes.iconbtn}
          disabled={disabled}
        >
          <RemoveIcon className={classes.icon} />
        </IconButton>
      </Grid>

      <Grid item>
        <Typography color={!disabled ? 'primary' : 'textSecondary'} style={{ width: 20, textAlign: 'center' }}>
          {value}
        </Typography>
      </Grid>

      <Grid item>
        <IconButton
          onClick={() :void => {
            if (value + 1 <= maxValue) {
              setValue(value + 1);
            }
          }}
          size="small"
          className={classes.iconbtn}
          disabled={disabled}
        >
          <AddIcon className={classes.icon} />
        </IconButton>
      </Grid>
    </Grid>
  );
}
