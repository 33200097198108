import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: any) => ({
  formControl: {
    padding: theme.spacing(0),
    minWidth: 50,
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: '0px',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
    color: theme.palette.primary.contrastText,
  },
  iconStyle: {
    position: 'absolute',
    right: '0',
    display: 'inline-block',
    pointerEvents: 'none',
  },
}));

export default function LanguageSelection(): JSX.Element {
  const classes = useStyles();

  const [language, setLanguage] = React.useState('EN');

  const handleChangeLanguage = (event): void => {
    setLanguage(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={language}
        onChange={handleChangeLanguage}
        displayEmpty
        className={classes.selectEmpty}
        disableUnderline
        IconComponent={(): JSX.Element => <ExpandMoreIcon className={classes.iconStyle} />}
      >
        <MenuItem value="EN">EN</MenuItem>
        <MenuItem value="FR">FR</MenuItem>
      </Select>
    </FormControl>
  );
}
