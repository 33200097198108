import React from 'react';
import {
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import TextField from 'components/ThinTextField';

interface Props {
    user: any,
    handle: any,
}

export default function UserInfos({
  user,
  handle,
}: Props): JSX.Element {
  return (
    <Grid
      container
      justifyContent="center"
      spacing={1}
    >
      <Grid item xs={12} md={12}>
        <Grid container justifyContent="space-between" spacing={2}>

          <Grid item xs={12} md={5}>
            <Grid container justifyContent="space-between" alignItems="center">

              <Grid item xs={5} lg={3}>
                <Typography align="left" color="textSecondary">
                  Gender
                </Typography>
              </Grid>

              <Grid item xs={12} lg={6} xl={8}>
                <RadioGroup row aria-label="gender" name="gender" value={user.gender} onChange={handle}>
                  <Grid container justifyContent="space-around">
                    <Grid item>
                      <FormControlLabel value="M" control={<Radio />} label="M" />
                    </Grid>
                    <Grid item>
                      <FormControlLabel value="F" control={<Radio />} label="F" />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </Grid>

            </Grid>
          </Grid>

        </Grid>
        <Grid container justifyContent="space-between" spacing={1}>

          <Grid item xs={12} md={6}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} md={4}>
                <Typography align="left" color="textSecondary">
                  First name
                </Typography>
              </Grid>

              <Grid item xs>
                <TextField
                  fullWidth
                  name="firstName"
                  margin="dense"
                  size="small"
                  type="text"
                  onChange={handle}
                  value={user.firstName}
                  variant="outlined"
                  required
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} md={4}>
                <Typography align="left" color="textSecondary">
                  Last name
                </Typography>
              </Grid>

              <Grid item xs>
                <TextField
                  fullWidth
                  name="lastName"
                  margin="dense"
                  size="small"
                  type="text"
                  onChange={handle}
                  value={user.lastName}
                  variant="outlined"
                  required
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={5} md={4} xl={4}>
                <Typography align="left" color="textSecondary">
                  E-mail
                </Typography>
              </Grid>

              <Grid item xs>
                <TextField
                  fullWidth
                  name="email"
                  margin="dense"
                  size="small"
                  type="text"
                  onChange={handle}
                  value={user.email}
                  variant="outlined"
                  required
                />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  );
}
