import { createTheme } from '@material-ui/core';
import commonTheme from './common';

const theme = createTheme({
  ...commonTheme,

  palette: {
    type: 'light',

    background: {
      default: '#F1F1F1',
    },

    primary: {
      main: '#004281',
      title: '#004281',
      contrastText: '#FFF',
    },

    secondary: {
      main: '#004281',
      contrastText: '#FFF',
    },

    menu: {
      background: '#004281',
    },

    toolbox: {
      background: '#FFF',
      contrastText: '#004281',
    },

    navbar: {
      background: '#004281',
      contrastText: '#FFF',

      selected: {
        background: '#FFF',
        contrastText: '#004281',
      },
    },

    cardTab: {
      background: '#004281',
      contrastText: '#FFF',

      selected: {
        background: '#FFF',
        contrastText: '#004281',
      },
    },

    verticalCardTab: {
      background: '#EEE',
      contrastText: '#000',

      selected: {
        background: '#004281',
        contrastText: '#FFF',
      },
    },

    stepper: {
      background: '#CCC',
    },
  },

  overrides: {
    ...commonTheme.overrides,

    MuiAppBar: {
      colorPrimary: {
        color: '#FFF',
        backgroundColor: '#004281',
      },
    },

    MuiOutlinedInput: {
      ...commonTheme.overrides.MuiOutlinedInput,

      root: {
        ...commonTheme.overrides.MuiOutlinedInput.root,

        color: '#000',
      },
    },

    MuiFormControlLabel: {
      ...commonTheme.overrides.MuiFormControlLabel,
      label: {
        ...commonTheme.overrides.MuiFormControlLabel.label,
        '.MuiRadio-root.Mui-checked + &': {
          ...commonTheme.overrides.MuiFormControlLabel.label['.MuiRadio-root.Mui-checked + &'],
          boxShadow: 'unset',
          color: '#004281',
        },
      },
    },

    MuiButton: {
      ...commonTheme.overrides.MuiButton,

      contained: {
        ...commonTheme.overrides.MuiButton.contained,

        color: '#004281',
        backgroundColor: '#FFF',
      },
    },

    MuiIconButton: {
      root: {
        '&:hover': {
          // backgroundColor: '#004281',
        },
      },
    },

    MuiDrawer: {
      ...commonTheme.overrides.MuiDrawer,

      root: {
        ...commonTheme.overrides.MuiDrawer.root,

        '& .MuiList-root': {
          ...commonTheme.overrides.MuiDrawer.root['& .MuiList-root'],

          '& .MuiListItem-root': {
            ...commonTheme.overrides.MuiDrawer.root['& .MuiList-root']['& .MuiListItem-root'],

            '&.Mui-selected': {
              color: '#004281',
              backgroundColor: '#FFF',
            },
          },
        },
      },

      paper: {
        ...commonTheme.overrides.MuiDrawer.paper,

        backgroundColor: '#004281',
        color: '#FFF',
      },

    },
  },
});
export default theme;
