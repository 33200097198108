import React, { useState } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import light from 'themes/light';
import PropTypes from 'prop-types';
import { CssBaseline } from '@material-ui/core';
import { responsiveFontSizes } from '@material-ui/core/styles';

const CustomThemesProvider = ({ children }) => {
  const [theme] = useState(light);

  return (
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

CustomThemesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomThemesProvider;
