import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';

import { useHistory } from 'react-router';

const textContent = [
  'We are proud and happy to start with you a new collaboration.',
  'Before starting, we have some questions in order to adapt our services to your expectation.',
  "First, let's check some information ! 😉",
];

interface Props {
  number: number,
  setNumber: any,
}

export default function WelcomeRegistration({
  number,
  setNumber,
}: Props): JSX.Element {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={6}
    >
      <Grid item xs={12}>
        <Typography align="center" color="primary" variant="h4" style={{ fontWeight: 'bold' }}>
          WELCOME TO RHYTHMSCOPE DIAGNOSIS SERVICES
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          direction="column"
          wrap="nowrap"
          justifyContent="center"
          alignItems="center"
        >
          {textContent && textContent.map((sentence) => (
            <Grid item key={sentence}>
              <Typography align="center" color="textSecondary" style={{ fontWeight: 'bold' }}>
                {sentence}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={(): void => setNumber(number + 1)}
        >
          <Typography>
            Let&apos;s go !
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
