import React, { useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Theme,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import AddRemoveButton from 'components/AddRemoveButton';

import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

const useStyles = makeStyles((theme: Theme) => ({
  noLabel: {
    '& .MuiFormControlLabel-label': {
      display: 'none',
    },
    '& .PrivateSwitchBase-root-11': {
      padding: 0,
    },
  },
  contact: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  iconbtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  icon: {
    fontSize: '0.8em',
  },
}));

interface Props {
  perLicence: boolean,
  setPerLicense: any,
  globalArchiving: boolean,
  setGlobalArchiving: any,
  nbSynescope: number,
  setNbSynescope: any,
  nbSynescopeUpload: number,
  setNbSynescopeUpload: any,
  nbArchivingPerLicense: number,
  setNbArchivingPerLicense: any,
}

export default function ChooseFormula({
  perLicence,
  setPerLicense,
  globalArchiving,
  setGlobalArchiving,
  nbSynescope,
  setNbSynescope,
  nbSynescopeUpload,
  setNbSynescopeUpload,
  nbArchivingPerLicense,
  setNbArchivingPerLicense,
}: Props): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  const cardContent = useMemo(() => (
    <Grid container justifyContent="space-between" alignItems="center" spacing={3}>

      <Grid item xs={12}>

        <Grid container alignItems="center" justifyContent="center" spacing={3}>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="small"
              onClick={(): void => setOpen(true)}
            >
              <Typography>
                Formula Information
              </Typography>
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between" spacing={2}>

              <Grid item md={6}>
                <Grid container justifyContent="center" spacing={2}>

                  <Grid item xs={12}>
                    <Typography align="center" variant="h6">
                      Formula
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <Typography align="left">
                      SyneScope
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <Typography align="left">
                      SyneScope + Upload
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <Grid container alignItems="center" justifyContent="space-between">

                      <Grid item xs={8} md={9} xl={6}>
                        <Typography align="left">
                          Archiving per License
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Checkbox
                          checked={perLicence}
                          onChange={(): void => {
                            setPerLicense(true);
                            setGlobalArchiving(false);
                          }}
                          name="perLicense"
                          className={classes.noLabel}
                        />
                      </Grid>

                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <Grid container alignItems="center" justifyContent="space-between">

                      <Grid item xs={8} md={9} xl={6}>
                        <Typography align="left">
                          Global archiving
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Checkbox
                          checked={globalArchiving}
                          onChange={(): void => {
                            setGlobalArchiving(true);
                            setNbArchivingPerLicense(0);
                            setPerLicense(false);
                          }}
                          name="globalArchiving"
                          className={classes.noLabel}
                        />
                      </Grid>

                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

              <Grid item md={6}>
                <Grid container justifyContent="center" spacing={2}>

                  <Grid item xs={12}>
                    <Typography align="center" variant="h6">
                      Quantity
                    </Typography>
                  </Grid>

                  <Grid item xs={8}>
                    <AddRemoveButton
                      value={nbSynescope}
                      setValue={setNbSynescope}
                    />
                  </Grid>

                  <Grid item xs={8}>
                    <AddRemoveButton
                      value={nbSynescopeUpload}
                      setValue={setNbSynescopeUpload}
                    />
                  </Grid>

                  <Grid item xs={8}>
                    <AddRemoveButton
                      value={nbArchivingPerLicense}
                      setValue={setNbArchivingPerLicense}
                      maxValue={nbSynescope + nbSynescopeUpload}
                      disabled={!perLicence}
                    />
                  </Grid>

                </Grid>
              </Grid>

            </Grid>
          </Grid>

        </Grid>

      </Grid>

      <Grid item xs={12} className={classes.contact}>

        <Grid container justifyContent="center" alignItems="center" spacing={2}>

          <Grid item xs={12}>
            <Typography variant="h6" color="primary" align="center">
              You need help to choose your formula ? Contact us !
            </Typography>
          </Grid>

          <Grid item xs={12} md={8} lg={11} xl={9}>
            <Grid container justifyContent="space-between" alignItems="center">

              <Grid item>
                <PhoneIcon />
              </Grid>

              <Grid item md={3}>
                <Typography variant="body2" align="right" color="textSecondary">
                  00.00.00.00.00
                </Typography>
              </Grid>

              <Grid item>
                <AlternateEmailIcon />
              </Grid>

              <Grid item md={3}>
                <Typography variant="body2" align="right" color="textSecondary">
                  support@crm.microport.com
                </Typography>
              </Grid>

            </Grid>
          </Grid>

        </Grid>

      </Grid>

    </Grid>
  ), [classes.contact, classes.noLabel, globalArchiving, nbArchivingPerLicense, nbSynescope,
    nbSynescopeUpload, perLicence, setGlobalArchiving, setNbArchivingPerLicense, setNbSynescope,
    setNbSynescopeUpload, setPerLicense]);

  return (
    <>
      <Dialog fullWidth maxWidth="md" onClose={handleClose} open={open}>
        <DialogTitle>
          <Typography variant="h6" color="primary">
            Formula Informations
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            In progress...
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            type="submit"
            size="small"
            onClick={handleClose}
          >
            <Typography>
              Close
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography gutterBottom align="center" color="primary" variant="h4" style={{ fontWeight: 'bold' }}>
            Choose your formula
          </Typography>
        </Grid>
        <Grid item xs={12} md={11} style={{ width: '100%' }}>
          {cardContent}
        </Grid>
      </Grid>
    </>
  );
}
