import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  scrollable: {
    height: '100%',
    maxHeight: '100%',

    width: '100%',
    maxWidth: '100%',

    overflow: 'hidden',

    '&::-webkit-scrollbar': {
      height: 8,
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.background.default,
      borderRadius: 1000,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 1000,
      boxShadow: `inset 0 0 0 8px ${theme.palette.primary.main}`,
      border: '1px solid transparent',
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        boxShadow: `inset 0 0 0 8px ${theme.palette.primary.light}`,
      },
    },

    scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.default}`,
    scrollbarWidth: 'thin',
  },
}));

interface Props{
  children: any,
  x: boolean,
  y: boolean
}

const ScrollableView = ({
  children,
  x,
  y,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={classes.scrollable}
      style={{
        overflowX: x ? 'auto' : 'hidden',
        overflowY: y ? 'auto' : 'hidden',
      }}
    >
      {children}
    </div>
  );
};

ScrollableView.defaultProps = {
  x: false,
  y: false,
};

ScrollableView.propTypes = {
  children: PropTypes.node.isRequired,
  x: PropTypes.bool,
  y: PropTypes.bool,
};

export default ScrollableView;
