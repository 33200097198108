import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Chip,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';

import TextField from 'components/ThinTextField';

import DialogLanguages from 'components/DialogLanguages';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import Cancel from '@material-ui/icons/Cancel';
import ScrollableView from 'components/ScrollableView';

const useStyles = makeStyles((theme: any) => ({
  gridLanguageButton: {
    [theme.breakpoints.down('lg')]: {
      margin: 'auto',
    },
  },
  selectChips: {
    borderRadius: 1000,
    paddingTop: '5px',
    paddingBottom: '5px',
    marginTop: '5px',
    backgroundColor: 'red',
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

interface Props {
  administrator: any,
  setAdministrator: any,
  handleAdministrator: any,
  users: any,
}

export default function AboutTheAdministrator({
  administrator,
  setAdministrator,
  handleAdministrator,
  users,
}: Props): JSX.Element {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [choice, setChoice] = React.useState('-1');

  const handleChoice = useCallback((e): void => {
    setChoice(e.target.value);
    switch (e.target.value) {
      case '0':
        setAdministrator(users[0]);
        break;
      case '1':
        setAdministrator(users[1]);
        break;
      case '2':
        setAdministrator(users[2]);
        break;
      case '3':
        setAdministrator(users[3]);
        break;
      default:
        break;
    }
  }, [setAdministrator, users]);

  const [administratorConfirmEmail, setAdministratorConfirmEmail] = React.useState('');
  const [administratorConfirmPassword, setAdministratorConfirmPassword] = React.useState('');

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmassword, setShowConfirmPassword] = React.useState(false);

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (value): void => {
    setOpen(false);
    if (!administrator.languages.includes(value) && typeof value !== 'undefined') {
      const result = administrator.languages.slice();
      result.push(value);
      const newAdministrator = {
        gender: administrator.gender,
        firstName: administrator.firstName,
        lastName: administrator.lastName,
        email: administrator.email,
        telephone: administrator.telephone,
        password: administrator.password,
        function: administrator.function,
        languages: result,
      };
      setAdministrator(newAdministrator);
    }
  };

  const handleDelete = useCallback((_, value): void => {
    const result = administrator.languages.filter((lang) => lang !== value);
    const newAdministrator = {
      gender: administrator.gender,
      firstName: administrator.firstName,
      lastName: administrator.lastName,
      email: administrator.email,
      telephone: administrator.telephone,
      password: administrator.password,
      function: administrator.function,
      languages: result,
    };
    setAdministrator(newAdministrator);
  }, [administrator, setAdministrator]);

  const handleAdministratorConfirmEmail = (e): void => {
    setAdministratorConfirmEmail(e.target.value);
  };
  const handleAdministratorConfirmPassword = (e): void => {
    setAdministratorConfirmPassword(e.target.value);
  };

  const formFirstColumn = useMemo(() => (
    <Grid container justifyContent="space-between" spacing={1}>

      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={5} xl={4}>
            <Typography align="left" color="textSecondary">
              Last Name *
            </Typography>
          </Grid>

          <Grid item xs={7} xl={8}>
            <TextField
              fullWidth
              name="lastName"
              margin="dense"
              size="small"
              type="text"
              onChange={handleAdministrator}
              value={administrator.lastName}
              variant="outlined"
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={5} xl={4}>
            <Typography align="left" color="textSecondary">
              First Name *
            </Typography>
          </Grid>

          <Grid item xs={7} xl={8}>
            <TextField
              fullWidth
              name="firstName"
              margin="dense"
              size="small"
              type="text"
              onChange={handleAdministrator}
              value={administrator.firstName}
              variant="outlined"
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={5} xl={4}>
            <Typography align="left" color="textSecondary">
              Mobile Phone Number *
            </Typography>
          </Grid>

          <Grid item xs={7} xl={8}>
            <TextField
              fullWidth
              name="telephone"
              margin="dense"
              size="small"
              type="text"
              onChange={handleAdministrator}
              value={administrator.telephone}
              variant="outlined"
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={5} xl={4}>
            <Typography align="left" color="textSecondary">
              Function
            </Typography>
          </Grid>

          <Grid item xs={7} xl={8}>
            <Select
              fullWidth
              name="function"
              margin="dense"
              type="text"
              value={administrator.function}
              onChange={handleAdministrator}
              variant="outlined"
            >
              <MenuItem value="Nurse">Nurse</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid item xs={5} md={4}>
            <Typography align="left" color="textSecondary">
              Speaking Languages
            </Typography>
          </Grid>

          <Grid item xs md={1} className={classes.gridLanguageButton}>
            <Tooltip title="Add Languages">
              <IconButton onClick={handleClickOpen} style={{ padding: 0 }}>
                <AddCircleRoundedIcon
                  className={classes.icon}
                />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item xs={12} md={7}>
            <Grid container wrap="wrap">
              {administrator.languages && administrator.languages.map((value) => (
                <Grid item>
                  <Chip
                    key={value}
                    label={value}
                    size="small"
                    onDelete={(e): void => handleDelete(e, value)}
                    deleteIcon={<Cancel className={classes.icon} />}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  ), [administrator, classes.gridLanguageButton, classes.icon, handleAdministrator, handleDelete]);

  const formSecondColumn = useMemo(() => (
    <Grid container spacing={1}>

      <Grid item xs={12}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={5} xl={4}>
            <Typography align="left" color="textSecondary">
              E-mail *
            </Typography>
          </Grid>

          <Grid item xs={7} xl={8}>
            <TextField
              fullWidth
              name="email"
              margin="dense"
              size="small"
              type="text"
              onChange={handleAdministrator}
              value={administrator.email}
              variant="outlined"
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={5} xl={4}>
            <Typography align="left" color="textSecondary">
              Confirm E-mail *
            </Typography>
          </Grid>

          <Grid item xs={7} xl={8}>
            <TextField
              fullWidth
              name="Confirm-mail"
              margin="dense"
              size="small"
              type="text"
              onChange={handleAdministratorConfirmEmail}
              variant="outlined"
              error={administrator.email !== administratorConfirmEmail}
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={5} xl={4}>
            <Typography align="left" color="textSecondary">
              Password
            </Typography>
          </Grid>

          <Grid item xs={7} xl={8}>
            <TextField
              fullWidth
              name="password"
              margin="dense"
              size="small"
              type={showPassword ? 'text' : 'password'}
              onChange={handleAdministrator}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(): void => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={5} xl={4}>
            <Typography align="left" color="textSecondary">
              Confirm Password
            </Typography>
          </Grid>

          <Grid item xs={7} xl={8}>
            <TextField
              fullWidth
              name="Confirm-password"
              margin="dense"
              size="small"
              type={showConfirmassword ? 'text' : 'password'}
              onChange={handleAdministratorConfirmPassword}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(): void => {
                        setShowConfirmPassword(!showConfirmassword);
                      }}
                    >
                      {showConfirmassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={administrator.password !== administratorConfirmPassword}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ), [administrator, administratorConfirmEmail, administratorConfirmPassword,
    handleAdministrator, showConfirmassword, showPassword]);

  const formAdministrator = useMemo(() => (
    <>
      <Hidden smDown>
        <Grid
          container
          justifyContent="center"
          spacing={1}
        >
          <Grid item>
            <Select
              fullWidth
              margin="dense"
              type="text"
              value={choice}
              onChange={handleChoice}
              variant="outlined"
            >
              <MenuItem value="-1">Add a person</MenuItem>

              {users[0].firstName !== '' && users[0].lastName !== '' && (
              <MenuItem value="0">{`${users[0].firstName} ${users[0].lastName}`}</MenuItem>
              )}

              {users[1].firstName !== '' && users[1].lastName !== ''
            && users[1] !== users[0] && (
              <MenuItem value="1">{`${users[1].firstName} ${users[1].lastName}`}</MenuItem>
              )}

              {users[2].firstName !== '' && users[2].lastName !== ''
            && users[2] !== users[1] && users[2] !== users[0] && (
              <MenuItem value="2">{`${users[2].firstName} ${users[2].lastName}`}</MenuItem>
              )}

              {users[3].firstName !== '' && users[3].lastName !== ''
            && users[3] !== users[0]
            && users[3] !== users[2]
            && users[3] !== users[1] && (
              <MenuItem value="3">{`${users[3].firstName} ${users[3].lastName}`}</MenuItem>
              )}
            </Select>
          </Grid>
          <Grid item xs={12} md={11}>
            <Grid container justifyContent="space-between" spacing={2}>

              <Grid item xs={12} md={6}>
                <Grid container justifyContent="space-between" alignItems="center">

                  <Grid item xs={5} lg={3}>
                    <Typography align="left" color="textSecondary">
                      Gender
                    </Typography>
                  </Grid>

                  <Grid item xs={7} lg={6} xl={8}>
                    <RadioGroup row aria-label="gender" name="gender" value={administrator.gender} onChange={handleAdministrator}>
                      <Grid container justifyContent="space-around">
                        <Grid item>
                          <FormControlLabel value="M" control={<Radio />} label="M" />
                        </Grid>
                        <Grid item>
                          <FormControlLabel value="F" control={<Radio />} label="F" />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </Grid>

                </Grid>
              </Grid>

            </Grid>
            <Grid container justifyContent="space-between" spacing={2}>

              <Grid item xs={12} md={6}>
                {formFirstColumn}
              </Grid>

              <Grid item xs={12} md={6}>
                {formSecondColumn}
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <ScrollableView y>
          <Grid
            container
            justifyContent="center"
            style={{ maxHeight: '50vh' }}
            spacing={1}
          >
            <Grid item>
              <Select
                fullWidth
                margin="dense"
                type="text"
                value={choice}
                onChange={handleChoice}
                variant="outlined"
              >
                <MenuItem value="-1">Add a person</MenuItem>

                {users[0].firstName !== '' && users[0].lastName !== '' && (
                <MenuItem value="0">{`${users[0].firstName} ${users[0].lastName}`}</MenuItem>
                )}

                {users[1].firstName !== '' && users[1].lastName !== ''
            && users[1] !== users[0] && (
              <MenuItem value="1">{`${users[1].firstName} ${users[1].lastName}`}</MenuItem>
                )}

                {users[2].firstName !== '' && users[2].lastName !== ''
            && users[2] !== users[1] && users[2] !== users[0] && (
              <MenuItem value="2">{`${users[2].firstName} ${users[2].lastName}`}</MenuItem>
                )}

                {users[3].firstName !== '' && users[3].lastName !== ''
            && users[3] !== users[0]
            && users[3] !== users[2]
            && users[3] !== users[1] && (
              <MenuItem value="3">{`${users[3].firstName} ${users[3].lastName}`}</MenuItem>
                )}
              </Select>
            </Grid>
            <Grid item xs={12} md={11}>
              <Grid container justifyContent="space-between" spacing={2}>

                <Grid item xs={12} md={6}>
                  <Grid container justifyContent="space-between" alignItems="center">

                    <Grid item xs={5} lg={3}>
                      <Typography align="left" color="textSecondary">
                        Gender
                      </Typography>
                    </Grid>

                    <Grid item xs={7} lg={6} xl={8}>
                      <RadioGroup row aria-label="gender" name="gender" value={administrator.gender} onChange={handleAdministrator}>
                        <Grid container justifyContent="space-around">
                          <Grid item>
                            <FormControlLabel value="M" control={<Radio />} label="M" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value="F" control={<Radio />} label="F" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>

                  </Grid>
                </Grid>

              </Grid>
              <Grid container justifyContent="space-between" spacing={2}>

                <Grid item xs={12} md={6}>
                  {formFirstColumn}
                </Grid>

                <Grid item xs={12} md={6}>
                  {formSecondColumn}
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </ScrollableView>
      </Hidden>
    </>
  ), [administrator.gender, choice, formFirstColumn, formSecondColumn,
    handleAdministrator, handleChoice, users]);

  return (
    <>

      <DialogLanguages open={open} onClose={handleClose} />

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography gutterBottom align="center" color="primary" variant="h4" style={{ fontWeight: 'bold' }}>
            Create an account
          </Typography>
          <Typography align="center" color="primary" variant="h5">
            About the administrator
          </Typography>
          <Typography align="center" color="textSecondary">
            The adminstrator will be the person who manage all the platform for the users.
          </Typography>
        </Grid>
        <Grid item xs={12} md={11} style={{ width: '100%' }}>
          {formAdministrator}
        </Grid>
      </Grid>
    </>
  );
}
