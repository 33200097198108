import React from 'react';
import {
  Grid,
  Hidden,
  Typography,
} from '@material-ui/core';

import ScrollableView from 'components/ScrollableView';
import ConfirmationCode from 'components/ConfirmationCode';

interface Props {
  userCodeSMS: string,
  handleUserCodeSMSChange: any,
}

export default function CheckInfosSMS({
  userCodeSMS,
  handleUserCodeSMSChange,
}: Props): JSX.Element {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography gutterBottom align="center" color="primary" variant="h4" style={{ fontWeight: 'bold' }}>
          Check informations
        </Typography>
        <Typography align="center" color="primary" variant="h5">
          You should receive an SMS from Microport.
        </Typography>
        <Typography align="center" color="primary" variant="h5">
          Tap the code here to confirm it.
        </Typography>
      </Grid>
      <Grid item xs={12} lg={10} style={{ width: '100%' }}>
        <Hidden smUp>
          <ScrollableView x>
            <ConfirmationCode
              code={userCodeSMS}
              onChange={handleUserCodeSMSChange}
            />
          </ScrollableView>
        </Hidden>
        <Hidden xsDown>
          <ConfirmationCode
            code={userCodeSMS}
            onChange={handleUserCodeSMSChange}
          />
        </Hidden>
      </Grid>
    </Grid>
  );
}
