export default class UserInformations {
    gender: string;

    firstName: string;

    lastName: string;

    email: string;

    telephone: string;

    password: string;

    function: Array<string>;

    languages: Array<string>;

    constructor() {
      this.gender = 'M';
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.telephone = '';
      this.password = '';
      this.function = [];
      this.languages = [];
    }
}
