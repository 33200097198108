import React from 'react';
import codes from '@cospired/i18n-iso-languages/langs/en.json';

import {
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';

import {
  Autocomplete,
} from '@material-ui/lab';

import ScrollableView from 'components/ScrollableView';

interface Props {
    onClose: any,
    open: any,
}

export default function DialogLanguages({
  onClose,
  open,
}: Props): JSX.Element {
  const handleClose = (): void => {
    onClose();
  };

  const handleListItemClick = (value): void => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-language"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <Autocomplete
        id="choose-language"
        options={Object.values(codes.languages).map((lang) => lang)}
        style={{ width: 300, margin: 'auto' }}
        renderInput={(params): JSX.Element => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label="Search input"
            margin="dense"
            variant="outlined"
          />
        )}
        onChange={(e, value): void => handleListItemClick(value)}
      />
      <Divider />
      <ScrollableView y>
        <List>
          {Object.values(codes.languages).map((lang) => (
            <ListItem
              key={lang}
              button
              onClick={(): void => handleListItemClick(lang)}
            >
              <ListItemText primary={lang} />
            </ListItem>
          ))}
        </List>
      </ScrollableView>
    </Dialog>
  );
}
