import React, { useCallback, useMemo } from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
} from '@material-ui/core';

import UserInformations from 'utils/UserInformations';

import Select from 'components/ThinSelect';

import UserInfos from 'components/UserInfos';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface Props {
  companyManager: any,
  purchaseManager: any,
  invoicesManager: any,
  dataProtectionOfficer: any,
  setPurchaseManager: any,
  setInvoicesManager: any,
  setDataProtectionOfficer: any,
  handleCompanyManager: any,
  handlePurchaseManager: any,
  handleInvoicesManager: any,
  handleDataProtectionOfficer: any,
}

export default function AboutYourCompany2({
  companyManager,
  purchaseManager,
  invoicesManager,
  dataProtectionOfficer,
  setPurchaseManager,
  setInvoicesManager,
  setDataProtectionOfficer,
  handleCompanyManager,
  handlePurchaseManager,
  handleInvoicesManager,
  handleDataProtectionOfficer,
}: Props): JSX.Element {
  const [choicePurchase, setChoicePurchase] = React.useState<string>('-1');
  const [choiceInvoices, setChoiceInvoices] = React.useState<string>('-1');
  const [choiceDataProtection, setChoiceDataProtection] = React.useState<string>('-1');

  const handleChoicePurchase = useCallback((e): void => {
    setChoicePurchase(e.target.value);
    switch (e.target.value) {
      case '0':
        setPurchaseManager(companyManager);
        break;
      case '2':
        setPurchaseManager(invoicesManager);
        break;
      case '3':
        setPurchaseManager(dataProtectionOfficer);
        break;
      default:
        setPurchaseManager(new UserInformations());
        break;
    }
  }, [companyManager, dataProtectionOfficer, invoicesManager, setPurchaseManager]);

  const handleChoiceInvoices = useCallback((e): void => {
    setChoiceInvoices(e.target.value);
    switch (e.target.value) {
      case '0':
        setInvoicesManager(companyManager);
        break;
      case '1':
        setInvoicesManager(purchaseManager);
        break;
      case '3':
        setInvoicesManager(dataProtectionOfficer);
        break;
      default:
        setInvoicesManager(new UserInformations());
        break;
    }
  }, [companyManager, dataProtectionOfficer, purchaseManager, setInvoicesManager]);

  const handleChoiceData = useCallback((e): void => {
    setChoiceDataProtection(e.target.value);
    switch (e.target.value) {
      case '0':
        setDataProtectionOfficer(companyManager);
        break;
      case '1':
        setDataProtectionOfficer(purchaseManager);
        break;
      case '2':
        setDataProtectionOfficer(invoicesManager);
        break;
      default:
        setDataProtectionOfficer(new UserInformations());
        break;
    }
  }, [companyManager, invoicesManager, purchaseManager, setDataProtectionOfficer]);

  const companyManagerAccordion = useMemo(() => (
    <Accordion defaultExpanded style={{ width: '100%' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="h6" color="primary">
          Company manager
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <UserInfos
          user={companyManager}
          handle={handleCompanyManager}
        />
      </AccordionDetails>
    </Accordion>
  ), [companyManager, handleCompanyManager]);

  const purchaseManagerAccordion = useMemo(() => (
    <Accordion style={{ width: '100%', pointerEvents: choicePurchase !== '-1' ? 'none' : 'all' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ display: choicePurchase !== '-1' ? 'none' : 'block' }} />}
      >
        <Grid container spacing={2}>
          <Grid item md={5}>
            <Typography variant="h6" color="primary">
              Purchase manager
            </Typography>
          </Grid>
          <Grid item>
            <Select
              fullWidth
              margin="dense"
              type="text"
              value={choicePurchase}
              onChange={handleChoicePurchase}
              onClick={(e): void => e.stopPropagation()}
              style={{ pointerEvents: 'all' }}
              variant="outlined"
            >
              <MenuItem value="-1">Add a person</MenuItem>
              {companyManager.firstName !== '' && companyManager.lastName !== '' && (
                <MenuItem value="0">{`${companyManager.firstName} ${companyManager.lastName}`}</MenuItem>
              )}
              {invoicesManager.firstName !== '' && invoicesManager.lastName !== ''
                && invoicesManager !== companyManager && (
                  <MenuItem value="2">{`${invoicesManager.firstName} ${invoicesManager.lastName}`}</MenuItem>
              )}
              {dataProtectionOfficer.firstName !== '' && dataProtectionOfficer.lastName !== ''
                && dataProtectionOfficer !== invoicesManager
                && dataProtectionOfficer !== companyManager && (
                  <MenuItem value="3">{`${dataProtectionOfficer.firstName} ${dataProtectionOfficer.lastName}`}</MenuItem>
              )}
            </Select>

          </Grid>
        </Grid>
      </AccordionSummary>
      {choicePurchase === '-1' && (
        <AccordionDetails>
          <UserInfos
            user={purchaseManager}
            handle={handlePurchaseManager}
          />
        </AccordionDetails>
      )}
    </Accordion>
  ), [choicePurchase, companyManager, dataProtectionOfficer, handleChoicePurchase,
    handlePurchaseManager, invoicesManager, purchaseManager]);

  const invoicesManagerAccordion = useMemo(() => (
    <Accordion style={{ width: '100%', pointerEvents: choiceInvoices !== '-1' ? 'none' : 'all' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ display: choiceInvoices !== '-1' ? 'none' : 'block' }} />}
      >
        <Grid container spacing={2}>
          <Grid item md={5}>
            <Typography variant="h6" color="primary">
              Invoices manager
            </Typography>
          </Grid>
          <Grid item>
            <Select
              fullWidth
              margin="dense"
              type="text"
              value={choiceInvoices}
              onChange={handleChoiceInvoices}
              onClick={(e): void => e.stopPropagation()}
              style={{ pointerEvents: 'all' }}
              variant="outlined"
            >
              <MenuItem value="-1">Add a person</MenuItem>
              {companyManager.firstName !== '' && companyManager.lastName !== '' && (
                <MenuItem value="0">{`${companyManager.firstName} ${companyManager.lastName}`}</MenuItem>
              )}
              {purchaseManager.firstName !== '' && purchaseManager.lastName !== ''
                && purchaseManager !== companyManager && (
                  <MenuItem value="1">{`${purchaseManager.firstName} ${purchaseManager.lastName}`}</MenuItem>
              )}
              {dataProtectionOfficer.firstName !== '' && dataProtectionOfficer.lastName !== ''
                && dataProtectionOfficer !== companyManager
                && dataProtectionOfficer !== purchaseManager && (
                  <MenuItem value="3">{`${dataProtectionOfficer.firstName} ${dataProtectionOfficer.lastName}`}</MenuItem>
              )}
            </Select>
          </Grid>
        </Grid>
      </AccordionSummary>
      {choiceInvoices === '-1' && (
        <AccordionDetails>
          <UserInfos
            user={invoicesManager}
            handle={handleInvoicesManager}
          />
        </AccordionDetails>
      )}
    </Accordion>
  ), [choiceInvoices, companyManager, dataProtectionOfficer, handleChoiceInvoices,
    handleInvoicesManager, invoicesManager, purchaseManager]);

  const dataProtectionOfficerAccordion = useMemo(() => (
    <Accordion style={{ width: '100%', pointerEvents: choiceDataProtection !== '-1' ? 'none' : 'all' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ display: choiceDataProtection !== '-1' ? 'none' : 'block' }} />}
      >
        <Grid container spacing={2}>
          <Grid item md={5}>
            <Typography variant="h6" color="primary">
              Data Protection Officer
            </Typography>
          </Grid>
          <Grid item>
            <Select
              fullWidth
              margin="dense"
              type="text"
              value={choiceDataProtection}
              onChange={handleChoiceData}
              onClick={(e): void => e.stopPropagation()}
              style={{ pointerEvents: 'all' }}
              variant="outlined"
            >
              <MenuItem value="-1">Add a person</MenuItem>
              {companyManager.firstName !== '' && companyManager.lastName !== '' && (
                <MenuItem value="0">{`${companyManager.firstName} ${companyManager.lastName}`}</MenuItem>
              )}
              {purchaseManager.firstName !== '' && purchaseManager.lastName !== ''
                && purchaseManager !== companyManager && (
                  <MenuItem value="1">{`${purchaseManager.firstName} ${purchaseManager.lastName}`}</MenuItem>
              )}
              {invoicesManager.firstName !== '' && invoicesManager.lastName !== ''
                && invoicesManager !== purchaseManager && invoicesManager !== companyManager && (
                  <MenuItem value="2">{`${invoicesManager.firstName} ${invoicesManager.lastName}`}</MenuItem>
              )}
            </Select>
          </Grid>
        </Grid>
      </AccordionSummary>
      {choiceDataProtection === '-1' && (
        <AccordionDetails>
          <UserInfos
            user={dataProtectionOfficer}
            handle={handleDataProtectionOfficer}
          />
        </AccordionDetails>
      )}
    </Accordion>
  ), [choiceDataProtection, companyManager, dataProtectionOfficer, handleChoiceData,
    handleDataProtectionOfficer, invoicesManager, purchaseManager]);

  const formCompany = (
    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        {companyManagerAccordion}
      </Grid>
      <Grid item xs={12}>
        {purchaseManagerAccordion}
      </Grid>
      <Grid item xs={12}>
        {invoicesManagerAccordion}
      </Grid>
      <Grid item xs={12}>
        {dataProtectionOfficerAccordion}
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography gutterBottom align="center" color="primary" variant="h4" style={{ fontWeight: 'bold' }}>
          Create an account
        </Typography>
        <Typography align="center" color="primary" variant="h5">
          About your company
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} style={{ width: '100%' }}>
        {formCompany}
      </Grid>
    </Grid>
  );
}
