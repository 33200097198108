import React, { useCallback } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Hidden,
  ListItem,
  Typography,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ScrollableView from 'components/ScrollableView';
import MoreLessButtons from 'components/MoreLessButtons';
import TextField from 'components/ThinTextField';
import Select from 'components/ThinSelect';

interface Props {
  maxUsers: number,
  nbUsers: number,
  nbSynescope: number,
  nbSynescopeUpload: number,
  nbArchivingPerLicense: number,
  usersName: Array<string>,
  usersFirstName: Array<string>,
  usersEmail: Array<string>,
  usersFunction: Array<string>,
  usersPhysicianID: Array<string>,
  usersLicense: Array<string>,
  usersOption: Array<string>,
  setNbUsers: any,
  setUsersName: any,
  setUsersFirstName: any,
  setUsersEmail: any,
  setUsersFunction: any,
  setUsersPhysicianID: any,
  setUsersLicense: any,
  setUsersOption: any,
  functions: any,
  licenses: any,
  options: any,
}

export default function AddUsers({
  maxUsers,
  nbUsers,
  nbSynescope,
  nbSynescopeUpload,
  nbArchivingPerLicense,
  usersName,
  usersFirstName,
  usersEmail,
  usersFunction,
  usersPhysicianID,
  usersLicense,
  usersOption,
  setNbUsers,
  setUsersName,
  setUsersFirstName,
  setUsersEmail,
  setUsersFunction,
  setUsersPhysicianID,
  setUsersLicense,
  setUsersOption,
  functions,
  licenses,
  options,
}: Props): JSX.Element {
  const handleUsersNameChange = useCallback((e, idx): void => {
    const newNames = usersName.slice();
    newNames[idx] = e.target.value;
    setUsersName(newNames);
  }, [setUsersName, usersName]);

  const handleUsersFirstNameChange = useCallback((e, idx): void => {
    const newFirstNames = usersFirstName.slice();
    newFirstNames[idx] = e.target.value;
    setUsersFirstName(newFirstNames);
  }, [setUsersFirstName, usersFirstName]);

  const handleUsersEmailChange = useCallback((e, idx): void => {
    const newEmails = usersEmail.slice();
    newEmails[idx] = e.target.value;
    setUsersEmail(newEmails);
  }, [setUsersEmail, usersEmail]);

  const handleUsersFunctionChange = useCallback((e, idx): void => {
    const newFunctions = usersFunction.slice();
    newFunctions[idx] = e.target.value;
    setUsersFunction(newFunctions);
  }, [setUsersFunction, usersFunction]);

  const handleUsersPhysicianIDChange = useCallback((e, idx): void => {
    const newPhysicianID = usersPhysicianID.slice();
    newPhysicianID[idx] = e.target.value;
    setUsersPhysicianID(newPhysicianID);
  }, [setUsersPhysicianID, usersPhysicianID]);

  const handleUsersLicenseChange = useCallback((e, idx): void => {
    const newLicense = usersLicense.slice();
    newLicense[idx] = e.target.value;
    setUsersLicense(newLicense);
  }, [setUsersLicense, usersLicense]);

  const handleUsersOptionsChange = useCallback((e, idx): void => {
    const newOptions = usersOption.slice();
    newOptions[idx] = e.target.value;
    setUsersOption(newOptions);
  }, [setUsersOption, usersOption]);

  const deleteUser = useCallback((idx): void => {
    if (idx > -1) {
      const newName = usersName.slice();
      const newFirstNames = usersFirstName.slice();
      const newEmail = usersEmail.slice();
      const newFunctions = usersFunction.slice();
      const newPhysicianID = usersPhysicianID.slice();
      const newLicense = usersLicense.slice();
      const newOption = usersOption.slice();

      newName.splice(idx, 1);
      newFirstNames.splice(idx, 1);
      newEmail.splice(idx, 1);
      newFunctions.splice(idx, 1);
      newPhysicianID.splice(idx, 1);
      newLicense.splice(idx, 1);
      newOption.splice(idx, 1);

      setUsersName(newName);
      setUsersFirstName(newFirstNames);
      setUsersEmail(newEmail);
      setUsersFunction(newFunctions);
      setUsersPhysicianID(newPhysicianID);
      setUsersLicense(newLicense);
      setUsersOption(newOption);
      setNbUsers(nbUsers - 1);
    }
  }, [nbUsers, setNbUsers, setUsersEmail, setUsersFirstName, setUsersFunction,
    setUsersLicense, setUsersName, setUsersOption, setUsersPhysicianID, usersEmail,
    usersFirstName, usersFunction, usersLicense, usersName, usersOption, usersPhysicianID]);

  const countOccurencesLicense = useCallback((license): boolean => {
    let cpt = 0;
    usersLicense.forEach((elem) => {
      if (elem === license) {
        cpt += 1;
      }
    });
    switch (license) {
      case 'SynesScope':
        return cpt >= nbSynescope;
      case 'SynesScope + Upload':
        return cpt >= nbSynescopeUpload;
      default:
        return true;
    }
  }, [nbSynescope, nbSynescopeUpload, usersLicense]);

  const countOccurencesOption = useCallback((option): boolean => {
    let cpt = 0;
    usersOption.forEach((elem) => {
      if (elem === option) {
        cpt += 1;
      }
    });
    switch (option) {
      case 'Archiving':
        return cpt >= nbArchivingPerLicense;
      default:
        return true;
    }
  }, [nbArchivingPerLicense, usersOption]);

  const addUsers = (
    <Grid container justifyContent="center" alignItems="center" spacing={1}>

      {new Array(nbUsers).fill('').map((_, idx) => (
        <Grid item xs={12}>
          <Accordion style={{ width: '100%' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Grid container alignItems="center" spacing={5}>
                {(usersFirstName[idx] === undefined || usersFirstName[idx] === '')
                      && (usersName[idx] === undefined || usersName[idx] === '') && (
                        <Grid item>
                          <Typography variant="h6" color="primary">
                            User
                            &nbsp;
                            {idx + 1}
                          </Typography>
                        </Grid>

                )}
                {(usersFirstName[idx] !== undefined
                      || usersName[idx] !== undefined) && (
                        <Grid item>
                          <Typography variant="h6" color="primary">
                            {usersFirstName[idx]}
                          &nbsp;
                            {usersName[idx]}
                          </Typography>
                        </Grid>
                )}
                <Grid item md>
                  <Typography variant="body1">
                    {usersFunction[idx]}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>

              <Hidden smDown>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>

                  <Grid item md={2}>
                    <Typography align="center">
                      Name
                    </Typography>
                  </Grid>

                  <Grid item md={2}>
                    <Typography align="center">
                      First name
                    </Typography>
                  </Grid>

                  <Grid item md={4}>
                    <Typography align="center">
                      Email
                    </Typography>
                  </Grid>

                  <Grid item md={3}>
                    <Typography align="center">
                      Function
                    </Typography>
                  </Grid>

                  <Grid item md={2}>
                    <TextField
                      fullWidth
                      name="lastName"
                      margin="dense"
                      size="small"
                      onChange={(e): void => handleUsersNameChange(e, idx)}
                      value={usersName[idx]}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={2}>
                    <TextField
                      fullWidth
                      name="firstName"
                      margin="dense"
                      size="small"
                      onChange={(e): void => handleUsersFirstNameChange(e, idx)}
                      value={usersFirstName[idx]}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={4}>
                    <TextField
                      fullWidth
                      name="email"
                      margin="dense"
                      size="small"
                      onChange={(e): void => handleUsersEmailChange(e, idx)}
                      value={usersEmail[idx]}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={3}>
                    <Select
                      fullWidth
                      name="function"
                      margin="dense"
                      type="text"
                      value={usersFunction[idx]}
                      onChange={(e): void => handleUsersFunctionChange(e, idx)}
                      variant="outlined"
                    >
                      {functions && functions.map((func) => (
                        <ListItem
                          value={func.label}
                        >
                          {func.text}
                        </ListItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item md={3}>
                    <Typography align="center">
                      Physician ID
                    </Typography>
                  </Grid>

                  <Grid item md={4}>
                    <Typography align="center">
                      License
                    </Typography>
                  </Grid>

                  <Grid item md={4}>
                    <Typography align="center">
                      Option
                    </Typography>
                  </Grid>

                  <Grid item md={3}>
                    <TextField
                      fullWidth
                      name="physicianID"
                      margin="dense"
                      size="small"
                      onChange={(e): void => handleUsersPhysicianIDChange(e, idx)}
                      value={usersPhysicianID[idx]}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={4}>
                    <Select
                      fullWidth
                      name="license"
                      margin="dense"
                      type="text"
                      value={usersLicense[idx]}
                      onChange={(e): void => handleUsersLicenseChange(e, idx)}
                      variant="outlined"
                    >
                      {licenses && licenses.map((license) => (
                        <ListItem
                          value={license.label}
                          style={{ pointerEvents: countOccurencesLicense(license.label) ? 'none' : 'all' }}
                          disabled={countOccurencesLicense(license.label)}
                        >
                          {license.text}
                        </ListItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item md={4}>
                    <Select
                      fullWidth
                      name="license"
                      margin="dense"
                      type="text"
                      value={usersOption[idx]}
                      onChange={(e): void => handleUsersOptionsChange(e, idx)}
                      variant="outlined"
                    >
                      {options && options.map((options) => (
                        <ListItem
                          value={options.label}
                          style={{ pointerEvents: countOccurencesOption(options.label) ? 'none' : 'all' }}
                          disabled={countOccurencesOption(options.label)}
                        >
                          {options.text}
                        </ListItem>
                      ))}
                    </Select>
                  </Grid>

                </Grid>
              </Hidden>

              <Hidden smUp>
                <ScrollableView y>
                  <Grid container justifyContent="space-between" alignItems="flex-start" style={{ maxHeight: '50vh' }} spacing={2}>

                    <Grid item xs={12}>
                      <Typography align="left">
                        Name
                      </Typography>
                    </Grid>

                    <Grid item xs={11}>
                      <TextField
                        fullWidth
                        name="lastName"
                        margin="dense"
                        size="small"
                        onChange={(e): void => handleUsersNameChange(e, idx)}
                        value={usersName[idx]}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography align="left">
                        First name
                      </Typography>
                    </Grid>

                    <Grid item xs={11}>
                      <TextField
                        fullWidth
                        name="firstName"
                        margin="dense"
                        size="small"
                        onChange={(e): void => handleUsersFirstNameChange(e, idx)}
                        value={usersFirstName[idx]}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography align="left">
                        Email
                      </Typography>
                    </Grid>

                    <Grid item xs={11}>
                      <TextField
                        fullWidth
                        name="email"
                        margin="dense"
                        size="small"
                        onChange={(e): void => handleUsersEmailChange(e, idx)}
                        value={usersEmail[idx]}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography align="left">
                        Function
                      </Typography>
                    </Grid>

                    <Grid item xs={11}>
                      <Select
                        fullWidth
                        name="function"
                        margin="dense"
                        type="text"
                        value={usersFunction[idx]}
                        onChange={(e): void => handleUsersFunctionChange(e, idx)}
                        variant="outlined"
                      >
                        {functions && functions.map((func) => (
                          <ListItem
                            value={func.label}
                          >
                            {func.text}
                          </ListItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography align="left">
                        Physician ID
                      </Typography>
                    </Grid>

                    <Grid item xs={11}>
                      <TextField
                        fullWidth
                        name="physicianID"
                        margin="dense"
                        size="small"
                        onChange={(e): void => handleUsersPhysicianIDChange(e, idx)}
                        value={usersPhysicianID[idx]}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography align="left">
                        License
                      </Typography>
                    </Grid>

                    <Grid item xs={11}>
                      <Select
                        fullWidth
                        name="license"
                        margin="dense"
                        type="text"
                        value={usersLicense[idx]}
                        onChange={(e): void => handleUsersLicenseChange(e, idx)}
                        variant="outlined"
                      >
                        {licenses && licenses.map((license) => (
                          <ListItem
                            value={license.label}
                            style={{ pointerEvents: countOccurencesLicense(license.label) ? 'none' : 'all' }}
                            disabled={countOccurencesLicense(license.label)}
                          >
                            {license.text}
                          </ListItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography align="left">
                        Option
                      </Typography>
                    </Grid>

                    <Grid item xs={11}>
                      <Select
                        fullWidth
                        name="license"
                        margin="dense"
                        type="text"
                        value={usersOption[idx]}
                        onChange={(e): void => handleUsersOptionsChange(e, idx)}
                        variant="outlined"
                      >
                        {options && options.map((options) => (
                          <ListItem
                            value={options.label}
                            style={{ pointerEvents: countOccurencesOption(options.label) ? 'none' : 'all' }}
                            disabled={countOccurencesOption(options.label)}
                          >
                            {options.text}
                          </ListItem>
                        ))}
                      </Select>
                    </Grid>

                  </Grid>
                </ScrollableView>
              </Hidden>

            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
      <MoreLessButtons
        idx={nbUsers - 1}
        nb={nbUsers}
        max={maxUsers}
        onClickMore={setNbUsers}
        onClickLess={deleteUser}
      />
    </Grid>
  );

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography gutterBottom align="center" color="primary" variant="h4" style={{ fontWeight: 'bold' }}>
          Add users
        </Typography>
      </Grid>
      <Grid item xs={12} md={11} style={{ width: '100%' }}>
        {addUsers}
      </Grid>
    </Grid>
  );
}
