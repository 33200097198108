import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';

const useStyles = makeStyles((theme: any) => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    backgroundColor: theme.palette.primary.contrastText,
  },
}));

interface Props {
    activeStep: number,
    numberOfSteps: number,
    nextButton: any,
    previousButton: any,
  }

export default function DotsStepperRegistration({
  activeStep,
  numberOfSteps,
  nextButton,
  previousButton,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <MobileStepper
      variant="dots"
      steps={numberOfSteps}
      position="static"
      activeStep={activeStep}
      className={classes.root}
      nextButton={nextButton}
      backButton={previousButton}
    />
  );
}
