import React from 'react';
import { makeStyles } from '@material-ui/core';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import CustomThemesProvider from 'themes/CustomThemesProvider';

import ConnectionPage from './pages/ConnectionPage';
import RegistrationPage from './pages/RegistrationPage';

const useStyles = makeStyles((theme) => ({
  fullViewport: {
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.background.default,
  },
}));

export default function App() {
  const classes = useStyles();

  return (
    <CustomThemesProvider>
      <div className={classes.fullViewport}>
        <Router basename="/">
          <Switch>
            <Route exact path="/" component={ConnectionPage} />
            <Route path="/registration" component={RegistrationPage} />
          </Switch>
        </Router>
      </div>
    </CustomThemesProvider>
  );
}
