import React from 'react';
import {
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

interface Props {
  handleCompany: any,
}

export default function AboutYourCompany({
  handleCompany,
}: Props): JSX.Element {
  const formCompany = (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} md={3}>
        <Typography align="left" color="textSecondary">
          Name
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          autoFocus
          fullWidth
          name="name"
          margin="dense"
          size="small"
          type="text"
          onChange={handleCompany}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <Typography align="left" color="textSecondary">
          Address
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          autoFocus
          fullWidth
          name="address"
          margin="dense"
          size="small"
          type="text"
          onChange={handleCompany}
          variant="outlined"
          multiline
          minRows={5}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <Typography align="left" color="textSecondary">
          Phone Number
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          autoFocus
          fullWidth
          name="telephone"
          margin="dense"
          size="small"
          type="text"
          onChange={handleCompany}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <Typography align="left" color="textSecondary">
          Identification Number (DUNS, SIRET...)
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          autoFocus
          fullWidth
          name="id"
          margin="dense"
          size="small"
          type="text"
          onChange={handleCompany}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography gutterBottom align="center" color="primary" variant="h4" style={{ fontWeight: 'bold' }}>
          Create an account
        </Typography>
        <Typography align="center" color="primary" variant="h5">
          About your company
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} style={{ width: '100%' }}>
        {formCompany}
      </Grid>
    </Grid>
  );
}
