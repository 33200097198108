import React from 'react';

import {
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  StepConnector,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import clsx from 'clsx';

import PropTypes from 'prop-types';

import PersonIcon from '@material-ui/icons/Person';
import InfoIcon from '@material-ui/icons/Info';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import PeopleIcon from '@material-ui/icons/People';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.action.disabled,
    zIndex: 1,
    color: theme.palette.primary.contrastText,
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: 1000,
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[4],
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.action.disabled,
    borderRadius: 1,
    marginLeft: 10,
    marginRight: 10,
  },
}))(StepConnector);

const steps = [
  { label: 'Create an account', icon: <PersonIcon /> },
  { label: 'Check informations', icon: <InfoIcon /> },
  { label: 'Choose your formula', icon: <FormatListBulletedIcon /> },
  { label: 'Add users', icon: <PeopleIcon /> },
  { label: 'Add devices', icon: <DevicesOtherIcon /> },
  { label: 'Congratulations', icon: <ThumbUpIcon /> },
];
function ColorlibStepIcon(props): JSX.Element {
  const classes = useStyles();
  const { active, completed, icon } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {steps[icon - 1].icon}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

interface Props {
  activeStep: number,
}

export default function StepperNavigation({
  activeStep,
}: Props): JSX.Element {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
    >
      {steps.map((step) => (
        <Step key={step.label}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            {step.label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
