import React, { useMemo } from 'react';

import {
  Button,
  Grid,
  Typography,
  makeStyles,
  Card,
  CardContent,
} from '@material-ui/core';

import { useHistory } from 'react-router';

import LogoMicroport from 'assets/microport_logo.svg';

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: '#FBFBFB',
  },
  connectButton: {
    borderRadius: 1000,
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    backgroundColor: theme.palette.primary.main,
  },
  createAccountButton: {
    borderRadius: 10,
  },
  title: {
    fontSize: 32,
    color: '#707070',
  },
  card: {
    padding: '20px 40px 30px 40px',
    borderRadius: 10,
    boxShadow: '0 0.1875rem 0.4375rem 0.1875rem rgba(3, 3, 3, 0.13), 0 0.6875rem 1.5rem 1rem rgba(3, 3, 3, 0.12)',
  },
}));

export default function ConnectionPageMicroport(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  const onConnectSynescope = (): void => {
    window.location.href = 'https://synescope.rhythmscope.com/';
  };

  const onConnectMyRhythm = (): void => {
    window.location.href = 'https://myrhythm.rhythmscope.com/';
  };

  const easyScopeWeb = useMemo(() => (
    <Card className={classes.card}>
      <CardContent>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item>
            <Typography align="center" className={classes.title}>
              SyneScope Web
            </Typography>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.connectButton}
              size="large"
              onClick={onConnectSynescope}
            >
              <Typography>
                Connect
              </Typography>
            </Button>
          </Grid>
          {/* <Grid item>
            <Button
              color="primary"
              className={classes.createAccountButton}
              onClick={(): void => history.push('/registration')}
            >
              <Typography color="primary" variant="body2" style={{ fontWeight: 'bold' }}>
                First connexion ? Create an account here !
              </Typography>
            </Button>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  ), [classes.card, classes.connectButton, classes.title]);

  const myRhythmView = useMemo(() => (
    <Card className={classes.card}>
      <CardContent>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item>
            <Typography align="center" className={classes.title}>
              My Rhythm View
            </Typography>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.connectButton}
              size="large"
              onClick={onConnectMyRhythm}
            >
              <Typography>
                Connect
              </Typography>
            </Button>
          </Grid>
          {/* <Grid item>
            <Button
              color="primary"
              className={classes.createAccountButton}
            >
              <Typography color="primary" variant="body2" style={{ fontWeight: 'bold' }}>
                First connexion ? Create an account here !
              </Typography>
            </Button>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  ), [classes.card, classes.connectButton, classes.title]);

  return (
    <Grid
      container
      justifyContent="space-around"
      alignItems="center"
      style={{ height: '100%' }}
      className={classes.root}
    >
      <Grid item xs={10} sm={5}>
        <img
          src={LogoMicroport}
          alt="Microport Logo"
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={10} sm={4} xl={3}>
        <Grid container spacing={5}>

          <Grid item>
            {easyScopeWeb}
          </Grid>

          <Grid item>
            {myRhythmView}
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  );
}
