import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { IconButton } from '@material-ui/core';

import { useHistory } from 'react-router';

import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

const useStyles = makeStyles((theme: any) => ({
  iconButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 1000,
    '&:hover': {
      '& .MuiIconButton-label': {
        '& .MuiSvgIcon-root': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  icon: {
    color: theme.palette.primary.contrastText,
  },
}));

interface Props {
    next?: boolean,
    number?: number,
    setNumber?: any,
    link?: any,
    disabled?: boolean,
}

export default function NavigationButton({
  next = false,
  number,
  setNumber,
  link = '',
  disabled = false,
}: Props): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  const handleForward = (): void => {
    if (number !== undefined) {
      setNumber(number + 1);
    }
  };

  const handleBackward = (): void => {
    if (number !== undefined) {
      if (number > 0) {
        setNumber(number - 1);
      }
    }
  };

  return (
    <>
      {link === '' ? (
        <IconButton
          className={classes.iconButton}
          aria-label={next ? 'Forward' : 'Back'}
          onClick={next ? handleForward : handleBackward}
          disabled={disabled}
        >
          {next ? <ArrowForwardIosRoundedIcon className={classes.icon} />
            : <ArrowBackIosRoundedIcon className={classes.icon} />}
        </IconButton>
      ) : (
        <IconButton
          className={classes.iconButton}
          aria-label={next ? 'Forward' : 'Back'}
          onClick={(): void => history.push(link)}
          disabled={disabled}
        >
          {next ? <ArrowForwardIosRoundedIcon className={classes.icon} />
            : <ArrowBackIosRoundedIcon className={classes.icon} />}
        </IconButton>
      )}
    </>
  );
}
