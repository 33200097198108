import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';

import { useHistory } from 'react-router';

import LanguageSelection from 'components/LanguageSelection';

import LogoMicroportHeader from 'assets/microport_logo_small.svg';

const useStyles = makeStyles(() => ({
  logoMicroport: {
    height: 30,
  },
}));

export default function HeaderRegistration(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppBar elevation={0} position="static">
      <Toolbar>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <IconButton
              onClick={(): void => history.push('/')}
              size="small"
            >
              <img
                src={LogoMicroportHeader}
                alt="Header Microport Logo"
                className={classes.logoMicroport}
              />
            </IconButton>
          </Grid>
          <Grid item xs={7} sm={4} md={3} lg={2}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                >
                  <Typography>
                    Contact Us
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <LanguageSelection />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
