import React, { useCallback } from 'react';
import {
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from '@material-ui/core';

import TextField from 'components/ThinTextField';

import ScrollableView from 'components/ScrollableView';
import MoreLessButtons from 'components/MoreLessButtons';

import SpiderFlashIcon from 'assets/images/SpiderFlash.svg';
import SpiderViewIcon from 'assets/images/SpiderView.svg';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

const useStyles = makeStyles(() => ({
  icon: {
    width: 40,
    height: 40,
    fill: '#A00',
  },
}));

interface Props {
  nbDevices: number,
  devicesSerial: Array<string>,
  devicesName: Array<string>,
  setNbDevices: any,
  setDevicesSerial: any,
  setDevicesName: any,
}

export default function AddDevices({
  nbDevices,
  devicesSerial,
  devicesName,
  setNbDevices,
  setDevicesSerial,
  setDevicesName,
}: Props): JSX.Element {
  const classes = useStyles();

  const handleLogo = useCallback((idx): any => {
    const newSerial = devicesSerial.slice();
    if (typeof newSerial[idx] !== 'undefined' && newSerial[idx].startsWith('SP')) {
      return (
        <img src={SpiderFlashIcon} alt="SpiderFlash Icon" className={classes.icon} />
      );
    }
    if (typeof newSerial[idx] !== 'undefined' && newSerial[idx].startsWith('SJ')) {
      return (
        <img src={SpiderViewIcon} alt="SpiderView Icon" className={classes.icon} />
      );
    }
    return (
      <HelpOutlineOutlinedIcon fontSize="large" />
    );
  }, [classes.icon, devicesSerial]);

  const handleDevicesSerialChange = useCallback((e, idx): void => {
    const newSerial = devicesSerial.slice();
    newSerial[idx] = e.target.value;
    setDevicesSerial(newSerial);
  }, [devicesSerial, setDevicesSerial]);

  const handleDevicesNameChange = useCallback((e, idx): void => {
    const newNames = devicesName.slice();
    newNames[idx] = e.target.value;
    setDevicesName(newNames);
  }, [devicesName, setDevicesName]);

  const deleteDevice = useCallback((idx): void => {
    if (idx > -1) {
      const newSerial = devicesSerial.slice();
      const newNames = devicesName.slice();

      newSerial.splice(idx, 1);
      newNames.splice(idx, 1);

      setDevicesSerial(newSerial);
      setDevicesName(newNames);
      setNbDevices(nbDevices - 1);
    }
  }, [devicesName, devicesSerial, nbDevices, setDevicesName, setDevicesSerial, setNbDevices]);

  const addDevices = (
    <>
      <Hidden smDown>
        <Grid container justifyContent="center" alignItems="center">

          <Grid item md={12}>
            <Grid container justifyContent="space-between" alignItems="center">

              <Grid item md={1}>
                <Typography>
                  Device
                </Typography>
              </Grid>

              <Grid item md={10}>
                <Typography align="center">
                  Serial Number
                </Typography>
              </Grid>

              {/*
              <Grid item md={5}>
                <Typography align="center">
                  Device name
                </Typography>
              </Grid>
              */}

            </Grid>
          </Grid>

        </Grid>
      </Hidden>
      <ScrollableView y>
        <Hidden mdUp>
          <Grid container justifyContent="center" alignItems="center" style={{ maxHeight: '40vh' }} spacing={3}>

            {new Array(nbDevices).fill('').map((_, idx) => (
              <Grid item xs={12}>

                <Grid container justifyContent="center" alignItems="center">

                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                      Device&nbsp;
                      {idx + 1}
                    </Typography>
                  </Grid>

                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      name="Serial"
                      placeholder="Device Serial"
                      margin="dense"
                      size="small"
                      onChange={(e): void => handleDevicesSerialChange(e, idx)}
                      variant="outlined"
                    />
                    {devicesSerial[idx]}
                  </Grid>

                  {/*
                  <Grid item xs={11}>
                    <TextField
                      fullWidth
                      name="Device Name"
                      placeholder="Device Name"
                      margin="dense"
                      size="small"
                      onChange={(e): void => handleDevicesNameChange(e, idx)}
                      variant="outlined"
                    />
                  </Grid>
                  */}

                  {idx === nbDevices - 1 && (
                  <Grid item xs={12}>
                    <MoreLessButtons
                      idx={idx}
                      nb={nbDevices}
                      onClickMore={setNbDevices}
                      onClickLess={deleteDevice}
                    />
                  </Grid>
                  )}

                </Grid>
              </Grid>
            ))}

          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid container justifyContent="center" alignItems="center">

            {new Array(nbDevices).fill('').map((_, idx) => (
              <Grid item md={12}>

                <Grid container justifyContent="space-between" alignItems="center">

                  <Grid item md={1}>
                    {handleLogo(idx)}
                  </Grid>

                  <Grid item md={10}>
                    <TextField
                      fullWidth
                      name="Serial"
                      margin="dense"
                      size="small"
                      onChange={(e): void => handleDevicesSerialChange(e, idx)}
                      variant="outlined"
                    />
                  </Grid>

                  {/*
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      name="Device Name"
                      margin="dense"
                      size="small"
                      onChange={(e): void => handleDevicesNameChange(e, idx)}
                      variant="outlined"
                    />
                  </Grid>
                  */}

                  {idx === nbDevices - 1 && (
                  <Grid item xs={12}>
                    <MoreLessButtons
                      idx={idx}
                      nb={nbDevices}
                      onClickMore={setNbDevices}
                      onClickLess={deleteDevice}
                    />
                  </Grid>
                  )}

                </Grid>
              </Grid>
            ))}

          </Grid>
        </Hidden>
      </ScrollableView>
    </>
  );

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography gutterBottom align="center" color="primary" variant="h4" style={{ fontWeight: 'bold' }}>
          Add devices
        </Typography>
      </Grid>
      <Grid item xs={12} md={11} style={{ width: '100%' }}>
        {addDevices}
      </Grid>
    </Grid>
  );
}
