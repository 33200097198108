import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const textContent = [
  { content: 'Welcome to RhythmScope Diagnosis Services !' },
  { content: 'Your team should receive an e-mail from us to create their account.' },
  { content: "Don't hesitate to give your feedback and contact us if you need help, we are here for you 😀" },
];

export default function Congratulations(): JSX.Element {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography gutterBottom align="center" color="primary" variant="h4" style={{ fontWeight: 'bold' }}>
          Congratulations !
        </Typography>
      </Grid>
      <Grid item xs={12} md={11} style={{ width: '100%' }}>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          {textContent && textContent.map((sentence) => (
            <Grid item xs={12} md={10}>
              <Typography align="center" color="textSecondary" style={{ fontWeight: 'bold' }}>
                {sentence.content}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
