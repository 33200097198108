import React from 'react';
import {
  Grid,
  Hidden,
  Typography,
} from '@material-ui/core';

import ScrollableView from 'components/ScrollableView';
import ConfirmationCode from 'components/ConfirmationCode';

interface Props {
  userCodeEmail: string,
  handleuserCodeEmailChange: any,
}

export default function CheckInfosEmail({
  userCodeEmail,
  handleuserCodeEmailChange,
}: Props): JSX.Element {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography gutterBottom align="center" color="primary" variant="h4" style={{ fontWeight: 'bold' }}>
          Check informations
        </Typography>
        <Typography align="center" color="primary" variant="h5">
          You should receive an e-mail from Microport.
        </Typography>
        <Typography align="center" color="primary" variant="h5">
          Tap the code here to confirm it.
        </Typography>
      </Grid>
      <Grid item xs={12} lg={10} style={{ width: '100%' }}>
        <Hidden smUp>
          <ScrollableView x>
            <ConfirmationCode
              code={userCodeEmail}
              onChange={handleuserCodeEmailChange}
            />
          </ScrollableView>
        </Hidden>
        <Hidden xsDown>
          <ConfirmationCode
            code={userCodeEmail}
            onChange={handleuserCodeEmailChange}
          />
        </Hidden>
      </Grid>
    </Grid>
  );
}
