import React from 'react';
import {
  Hidden,
} from '@material-ui/core';

import StepperRegistration from 'components/stepper/StepperRegistration';
import DotsStepperRegistration from 'components/stepper/DotsStepperRegistration';

interface Props {
    activeStep: number,
    numberOfSteps: number,
    previousButton: any,
    nextButton: any,
}

export default function ResponsiveStepper({
  activeStep,
  numberOfSteps,
  previousButton,
  nextButton,
}: Props): JSX.Element {
  return (
    <>
      <Hidden smDown>
        <StepperRegistration
          activeStep={activeStep}
        />
      </Hidden>
      <Hidden mdUp>
        <DotsStepperRegistration
          activeStep={activeStep}
          numberOfSteps={numberOfSteps}
          previousButton={previousButton}
          nextButton={nextButton}
        />
      </Hidden>
    </>
  );
}
