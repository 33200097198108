import React from 'react';
import { TextField, withStyles, TextFieldProps } from '@material-ui/core';

const ThinTextField = withStyles(() => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    '& .MuiInputBase-multiline': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiInputBase-input': {
      paddingTop: 6,
      paddingBottom: 6,
    },
    '& .MuiInputBase-inputMarginDense': {
      paddingTop: 6,
      paddingBottom: 6,
    },
  },
  // eslint-disable-next-line react/jsx-props-no-spreading
}))((props: TextFieldProps) => <TextField {...props} />);

export default ThinTextField;
