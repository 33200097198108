import React from 'react';
import { Select, withStyles, SelectProps } from '@material-ui/core';

const ThinSelect = withStyles(() => ({
  root: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  // eslint-disable-next-line react/jsx-props-no-spreading
}))((props: SelectProps) => <Select {...props} />);

export default ThinSelect;
