import React, { useEffect, useMemo } from 'react';

import {
  Card,
  CardContent,
  Grid,
  Hidden,
  makeStyles,
} from '@material-ui/core';

import UserInformations from 'utils/UserInformations';

import HeaderRegistration from 'components/HeaderRegistration';
import ScrollableView from 'components/ScrollableView';

import WelcomeRegistration from 'components/registrationCards/WelcomeRegistration';
import AboutYourCompany from 'components/registrationCards/AboutYourCompany';
import AboutYourCompany2 from 'components/registrationCards/AboutYourCompany2';
import AboutTheAdministrator from 'components/registrationCards/AboutTheAdministrator';
import CheckInfosEmail from 'components/registrationCards/CheckInfosEmail';
import CheckInfosSMS from 'components/registrationCards/CheckInfosSMS';
import ChooseFormula from 'components/registrationCards/ChooseFormula';
import AddUsers from 'components/registrationCards/AddUsers';
import AddDevices from 'components/registrationCards/AddDevices';
import Congratulations from 'components/registrationCards/Congratulations';
import NavigationButton from 'components/NavigationButton';
import ResponsiveStepper from 'components/stepper/ResponsiveStepper';

const useStyles = makeStyles(() => ({
  card: {
    minWidth: '80vw',
    maxWidth: '80vw',
    padding: '20px 20px 20px 20px',
    borderRadius: 10,
    boxShadow: '0 0.1875rem 0.4375rem 0.1875rem rgba(3, 3, 3, 0.13), 0 0.6875rem 1.5rem 1rem rgba(3, 3, 3, 0.12)',
  },
  fullHeight: {
    height: '100%',
  },
  disableScroll: {
    height: '100%',
  },
}));

const functions = [
  { label: 'Physician', text: 'Physician' },
  { label: 'Medical Staff', text: 'Medical Staff' },
  { label: 'Administrative Staff', text: 'Administrative Staff' },
];

const licenses = [
  { label: 'SynesScope', text: 'SynesScope' },
  { label: 'SynesScope + Upload', text: 'SynesScope + Upload' },
];

const options = [
  { label: 'Archiving', text: 'Archiving' },
];

export default function RegistrationPage(): JSX.Element {
  const classes = useStyles();

  const [nbPage, setNbPage] = React.useState<number>(0);
  const [activeStep, setActiveStep] = React.useState<number>(-1);
  // ----------------------------------------------------------------------------------------------
  // AboutYourCompany
  const [company, setCompany] = React.useState<any>({
    name: '',
    address: '',
    telephone: '',
    id: '',
  });

  const handleCompany = (e): void => {
    setCompany((prevCompany) => ({ ...prevCompany, [e.target.name]: e.target.value }));
  };

  // ----------------------------------------------------------------------------------------------
  // AboutYourCompany2
  const [companyManager, setCompanyManager] = React.useState<UserInformations>(
    new UserInformations(),
  );

  const [purchaseManager, setPurchaseManager] = React.useState<UserInformations>(
    new UserInformations(),
  );

  const [invoicesManager, setInvoicesManager] = React.useState<UserInformations>(
    new UserInformations(),
  );

  const [dataProtectionOfficer, setDataProtectionOfficer] = React.useState<UserInformations>(
    new UserInformations(),
  );

  const handleCompanyManager = (e): void => {
    setCompanyManager((prevUser) => ({ ...prevUser, [e.target.name]: e.target.value }));
  };
  const handlePurchaseManager = (e): void => {
    setPurchaseManager((prevUser) => ({ ...prevUser, [e.target.name]: e.target.value }));
  };
  const handleInvoicesManager = (e): void => {
    setInvoicesManager((prevUser) => ({ ...prevUser, [e.target.name]: e.target.value }));
  };
  const handleDataProtectionOfficer = (e): void => {
    setDataProtectionOfficer((prevUser) => ({ ...prevUser, [e.target.name]: e.target.value }));
  };

  // ----------------------------------------------------------------------------------------------
  // AboutTheAdministrator
  const [administrator, setAdministrator] = React.useState<UserInformations>(
    new UserInformations(),
  );

  const handleAdministrator = (e): void => {
    setAdministrator(
      (prevAdministrator) => ({ ...prevAdministrator, [e.target.name]: e.target.value }),
    );
  };

  // ----------------------------------------------------------------------------------------------
  // CheckInfosEmail
  const [userCodeEmail, setUserCodeEmail] = React.useState('');
  const [disabledEmail, setDisabledEmail] = React.useState(true);

  const handleuserCodeEmailChange = (value): void => {
    setUserCodeEmail(value);
  };

  useEffect(() => {
    if (userCodeEmail.length === 10) {
      setDisabledEmail(false);
    } else {
      setDisabledEmail(true);
    }
  }, [userCodeEmail.length]);

  // ----------------------------------------------------------------------------------------------
  // CheckInfosSMS
  const [userCodeSMS, setUserCodeSMS] = React.useState('');
  const [disabledSMS, setDisabledSMS] = React.useState(true);

  const handleUserCodeSMSChange = (value): void => {
    setUserCodeSMS(value);
  };

  useEffect(() => {
    if (userCodeSMS.length === 10) {
      setDisabledSMS(false);
    } else {
      setDisabledSMS(true);
    }
  }, [userCodeSMS.length]);

  // ----------------------------------------------------------------------------------------------
  // ChooseFormula
  const [perLicence, setPerLicense] = React.useState<boolean>(true);
  const [globalArchiving, setGlobalArchiving] = React.useState<boolean>(false);

  const [nbSynescope, setNbSynescope] = React.useState<number>(10);
  const [nbSynescopeUpload, setNbSynescopeUpload] = React.useState<number>(10);
  const [nbArchivingPerLicense, setNbArchivingPerLicense] = React.useState<number>(0);

  useEffect(() => {
    if (nbSynescope + nbSynescopeUpload < nbArchivingPerLicense) {
      setNbArchivingPerLicense(nbSynescope + nbSynescopeUpload);
    }
  }, [nbArchivingPerLicense, nbSynescope, nbSynescopeUpload]);

  const [companyMembers, setCompanyMembers] = React.useState([
    companyManager,
    purchaseManager,
    invoicesManager,
    dataProtectionOfficer,
  ]);

  // ----------------------------------------------------------------------------------------------
  // AddUsers

  const [maxUsers, setMaxUsers] = React.useState(1);
  const [nbUsers, setNbUsers] = React.useState(1);

  const [usersName, setUsersName] = React.useState<Array<string>>(new Array<string>(nbUsers));
  const [usersFirstName, setUsersFirstName] = React.useState<Array<string>>(
    new Array<string>(nbUsers),
  );
  const [usersEmail, setUsersEmail] = React.useState<Array<string>>(new Array<string>(nbUsers));
  const [usersFunction, setUsersFunction] = React.useState<Array<string>>(
    new Array<string>(nbUsers),
  );
  const [usersPhysicianID, setUsersPhysicianID] = React.useState<Array<string>>(
    new Array<string>(nbUsers),
  );
  const [usersLicense, setUsersLicense] = React.useState<Array<string>>(
    (new Array<string>(nbUsers)).fill(''),
  );
  const [usersOption, setUsersOption] = React.useState<Array<string>>(
    (new Array<string>(nbUsers)).fill(''),
  );

  useEffect(() => {
    setMaxUsers(nbSynescope + nbSynescopeUpload);
    if (nbSynescope + nbSynescopeUpload < nbUsers) {
      setNbUsers(nbSynescope + nbSynescopeUpload);
    }
    setUsersLicense(new Array<string>(nbUsers).fill(''));
    setUsersOption(new Array<string>(nbUsers).fill(''));
  }, [maxUsers, nbSynescope, nbSynescopeUpload, nbUsers]);

  // ----------------------------------------------------------------------------------------------
  // AddDevices
  const [nbDevices, setNbDevices] = React.useState(1);
  const [devicesSerial, setDevicesSerial] = React.useState<Array<string>>(new Array<string>());
  const [devicesName, setDevicesName] = React.useState<Array<string>>(new Array<string>());

  // ----------------------------------------------------------------------------------------------
  useEffect(() => {
    setCompanyMembers([companyManager, purchaseManager, invoicesManager, dataProtectionOfficer]);
  }, [companyManager, dataProtectionOfficer, invoicesManager, purchaseManager]);

  const currentPage = useMemo((): JSX.Element => {
    switch (nbPage) {
      case 0:
        setActiveStep(-1);
        return (
          <WelcomeRegistration
            number={nbPage}
            setNumber={setNbPage}
          />
        );
        break;
      case 1:
        setActiveStep(0);
        return (
          <AboutYourCompany
            handleCompany={handleCompany}
          />
        );
        break;
      case 2:
        setActiveStep(0);
        return (
          <AboutYourCompany2
            companyManager={companyManager}
            purchaseManager={purchaseManager}
            invoicesManager={invoicesManager}
            dataProtectionOfficer={dataProtectionOfficer}
            setPurchaseManager={setPurchaseManager}
            setInvoicesManager={setInvoicesManager}
            setDataProtectionOfficer={setDataProtectionOfficer}
            handleCompanyManager={handleCompanyManager}
            handlePurchaseManager={handlePurchaseManager}
            handleInvoicesManager={handleInvoicesManager}
            handleDataProtectionOfficer={handleDataProtectionOfficer}
          />
        );
        break;
      case 3:
        setActiveStep(0);
        return (
          <AboutTheAdministrator
            administrator={administrator}
            setAdministrator={setAdministrator}
            handleAdministrator={handleAdministrator}
            users={companyMembers}
          />
        );
        break;
      case 4:
        setActiveStep(1);
        return (
          <CheckInfosEmail
            userCodeEmail={userCodeEmail}
            handleuserCodeEmailChange={handleuserCodeEmailChange}
          />
        );
        break;
      case 5:
        setActiveStep(1);
        return (
          <CheckInfosSMS
            userCodeSMS={userCodeSMS}
            handleUserCodeSMSChange={handleUserCodeSMSChange}
          />
        );
        break;
      case 6:
        setActiveStep(2);
        return (
          <ChooseFormula
            perLicence={perLicence}
            setPerLicense={setPerLicense}
            globalArchiving={globalArchiving}
            setGlobalArchiving={setGlobalArchiving}
            nbSynescope={nbSynescope}
            setNbSynescope={setNbSynescope}
            nbSynescopeUpload={nbSynescopeUpload}
            setNbSynescopeUpload={setNbSynescopeUpload}
            nbArchivingPerLicense={nbArchivingPerLicense}
            setNbArchivingPerLicense={setNbArchivingPerLicense}
          />
        );
        break;
      case 7:
        setActiveStep(3);
        return (
          <AddUsers
            maxUsers={maxUsers}
            nbUsers={nbUsers}
            nbSynescope={nbSynescope}
            nbSynescopeUpload={nbSynescopeUpload}
            nbArchivingPerLicense={nbArchivingPerLicense}
            usersName={usersName}
            usersFirstName={usersFirstName}
            usersEmail={usersEmail}
            usersFunction={usersFunction}
            usersPhysicianID={usersPhysicianID}
            usersLicense={usersLicense}
            usersOption={usersOption}
            setNbUsers={setNbUsers}
            setUsersName={setUsersName}
            setUsersFirstName={setUsersFirstName}
            setUsersEmail={setUsersEmail}
            setUsersFunction={setUsersFunction}
            setUsersPhysicianID={setUsersPhysicianID}
            setUsersLicense={setUsersLicense}
            setUsersOption={setUsersOption}
            functions={functions}
            licenses={licenses}
            options={options}
          />
        );
        break;
      case 8:
        setActiveStep(4);
        return (
          <AddDevices
            nbDevices={nbDevices}
            devicesSerial={devicesSerial}
            devicesName={devicesName}
            setNbDevices={setNbDevices}
            setDevicesSerial={setDevicesSerial}
            setDevicesName={setDevicesName}
          />
        );
        break;
      case 9:
        setActiveStep(5);
        return (
          <Congratulations />
        );
        break;
      default:
        setActiveStep(-1);
        return (
          <WelcomeRegistration
            number={nbPage}
            setNumber={setNbPage}
          />
        );
    }
  }, [administrator, companyManager, dataProtectionOfficer, globalArchiving, invoicesManager,
    nbArchivingPerLicense, nbPage, nbSynescope, nbSynescopeUpload, perLicence, purchaseManager,
    userCodeEmail, userCodeSMS, companyMembers, nbUsers, usersName, usersFirstName, usersEmail,
    usersFunction, usersPhysicianID, usersLicense, usersOption, maxUsers, nbDevices,
    devicesSerial, devicesName]);

  const previousButton = useMemo((): JSX.Element => {
    if (activeStep >= 0) {
      return (
        <NavigationButton
          number={nbPage}
          setNumber={setNbPage}
        />
      );
    }
    return (<></>);
  }, [activeStep, nbPage]);

  const nextButton = useMemo((): JSX.Element => {
    if (activeStep >= 0) {
      if (activeStep === 1 && nbPage === 4) {
        return (
          <NavigationButton
            next
            number={nbPage}
            setNumber={setNbPage}
            disabled={disabledEmail}
          />
        );
      }
      if (activeStep === 1 && nbPage === 5) {
        return (
          <NavigationButton
            next
            number={nbPage}
            setNumber={setNbPage}
            disabled={disabledSMS}
          />
        );
      }
      if (activeStep === 5) {
        return (
          <NavigationButton
            next
            link="/"
          />
        );
      }
      return (
        <NavigationButton
          next
          number={nbPage}
          setNumber={setNbPage}
        />
      );
    }
    return (<></>);
  }, [activeStep, disabledEmail, disabledSMS, nbPage]);

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.fullHeight}
    >
      <Grid item>
        <HeaderRegistration />
      </Grid>

      <ScrollableView y>
        <Grid
          item
          className={classes.disableScroll}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.disableScroll}
          >
            <Grid item>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container alignItems="center" justifyContent="center" spacing={1}>

                    <Hidden smDown>
                      <Grid item>
                        {previousButton}
                      </Grid>
                    </Hidden>

                    <Grid item xs={12} md={10}>
                      {currentPage}
                    </Grid>

                    <Hidden smDown>
                      <Grid item>
                        {nextButton}
                      </Grid>
                    </Hidden>

                  </Grid>
                </CardContent>
                {activeStep >= 0 && (
                  <ResponsiveStepper
                    activeStep={activeStep}
                    numberOfSteps={6}
                    previousButton={previousButton}
                    nextButton={nextButton}
                  />
                )}
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </ScrollableView>
    </Grid>
  );
}
