import React from 'react';
import ReactCodeInput from 'react-code-input';

const inputStyle: React.CSSProperties = {
  fontFamily: 'FiraSans, Arial',
  borderColor: '#004281',
  borderWidth: 1,
  textAlign: 'center',
  borderRadius: 15,
  width: 50,
  height: 70,
  margin: '0px 6px',
  fontSize: 30,
};

const style: React.CSSProperties = {
  width: '100%',
  textAlign: 'center',
  whiteSpace: 'nowrap',
};

interface Props {
  code: string,
  onChange: any,
}

export default function ConfirmationCode({
  code,
  onChange,
}: Props): JSX.Element {
  return (
    <div style={style}>
      <ReactCodeInput
        type="text"
        fields={10}
        value={code}
        onChange={onChange}
        name="ConfirmationCode"
        inputMode="latin"
        forceUppercase
        inputStyle={inputStyle}
        // style={style} // Not working ?
      />
    </div>
  );
}
