import React from 'react';
import {
  Grid,
  IconButton,
} from '@material-ui/core';

import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import RemoveCircleOutlined from '@material-ui/icons/RemoveCircleOutlined';

interface Props {
    idx: number,
    nb: number,
    max?: number,
    onClickMore: any,
    onClickLess: any,
}

export default function MoreLessButtons({
  idx,
  nb,
  max = 100,
  onClickMore,
  onClickLess,
}: Props): JSX.Element {
  return (
    <Grid container justifyContent="center">

      {idx < max - 1 && (
      <Grid item>
        <IconButton
          onClick={(): void => onClickMore(nb + 1)}
        >
          <AddCircleOutlinedIcon color="primary" fontSize="large" />
        </IconButton>
      </Grid>
      )}

      {idx !== 0 && (
      <Grid item>
        <IconButton
          onClick={(): void => onClickLess(idx)}
        >
          <RemoveCircleOutlined color="primary" fontSize="large" />
        </IconButton>
      </Grid>
      )}

    </Grid>
  );
}
