import React from 'react';
import firaSans from 'assets/FiraSans';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

const theme = {
  props: {
    MuiCheckbox: {
      icon: <CircleUnchecked />,
      checkedIcon: <CircleCheckedFilled />,
    },
  },

  typography: {
    fontFamily: 'FiraSans, Arial',
  },

  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [firaSans],
      },
    },

    MuiChip: {
      icon: {
        color: 'inherit',
      },
    },

    MuiSelect: {
      outlined: {
        borderRadius: 1000,
        '&:focus': {
          borderRadius: 1000,
        },
      },
    },

    MuiButton: {
      root: {
        textTransform: 'capitalize',
      },
      contained: {
        borderRadius: 1000,
      },
      outlined: {
        borderRadius: 1000,
      },
    },

    MuiOutlinedInput: {
      root: {
        borderRadius: 1000,
        '&$focused': {
          borderRadius: 1000,
        },
      },
      multiline: {
        borderRadius: 20,
        '&$focused': {
          borderRadius: 20,
        },
      },
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 0px #CCC inset',
          WebkitTextFillColor: '#000',
        },
      },
    },

    MuiFormGroup: {
      root: {
        alignItems: 'center',
      },
      row: {
        justifyContent: 'center',
      },
    },

    MuiFormControlLabel: {
      root: {
        '& .MuiRadio-root': {
          // display: 'none',
        },
      },
      label: {
        '.MuiRadio-root + &': {
          // padding: '0.15em 1ch',
        },
        '.MuiRadio-root.Mui-checked + &': {
          borderRadius: 1000,
          boxShadow: '10px 5px 5px red',
        },
      },
    },

    MuiTableCell: {
      sizeSmall: {
        padding: '2px 3px 2px 3px',
        '&:last-child': {
          paddingRight: 0,
        },
      },
    },

    MuiDrawer: {
      root: {
        height: '100%',
        maxHeight: '100%',
        '& .MuiList-root': {
          '& .MuiListItem-root': {
            borderRadius: '0px 1000px 1000px 0px',
            '& .MuiListItemIcon-root': {
              color: 'inherit',
            },
          },
        },
      },
    },
    MuiButtonGroup: {
      root: {
        borderRadius: 1000,
      },
    },
  },
};

export default theme;
